import React from "react";
import {
  Calendar,
  DashboardIcon,
  DownArrow,
  FolderIcon,
  SubscriptionsIcon,
  UserIcon,
  PagesIcon,
  AdminIcon,
  AiQueries,
} from "../components/svgicons";
import BrandLogo from "../images/brand-logo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDrawerValue,
  setDrawerClose,
} from "../views/Dashboard/DashboardSlice";

function Drawer() {
  const url = window.location.href;
  const endpoint = new URL(url).pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DrawerValue = useSelector(getDrawerValue);
  return (
    <ul
      className="navbar-nav sidebar sidebar-dark accordion customSidebar"
      id="accordionSidebar"
      style={{ backgroundColor: "#283947" }}
    >
      {/*
<!-- Sidebar - Brand -->*/}
      <a className="d-flex align-items-center justify-content-center py-3">
        <div
          style={{ cursor: "pointer" }}
          className="brand-logo"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <img src={BrandLogo} style={{ width: "170px" }} />
        </div>
      </a>
      {/*
<!-- Divider -->*/}
      <li
        className={endpoint === "/dashboard" ? "nav-item active " : "nav-item"}
      >
        <a
          className="nav-link"
          onClick={() => {
            dispatch(setDrawerClose(!DrawerValue));
            navigate("/dashboard");
          }}
        >
          <DashboardIcon />
          <span>Dashboard</span>
        </a>
      </li>
      <li
        className={
          endpoint === "/users" || endpoint === "/user-details"
            ? "nav-item active"
            : "nav-item"
        }
      >
        <a
          className="nav-link"
          onClick={() => {
            dispatch(setDrawerClose(!DrawerValue));
            navigate("/users");
          }}
        >
          <UserIcon />
          <span>User</span>
        </a>
      </li>
      <li
        className={
          endpoint === "/subscriptions" ? "nav-item active " : "nav-item"
        }
      >
        <a
          className="nav-link"
          onClick={() => {
            dispatch(setDrawerClose(!DrawerValue));
            navigate("/subscriptions");
          }}
        >
          <SubscriptionsIcon />
          <span>Subscriptions</span>
        </a>
      </li>
      <li className={endpoint === "/pages" ? "nav-item active " : "nav-item"}>
        <a
          className="nav-link"
          onClick={() => {
            dispatch(setDrawerClose(!DrawerValue));
            navigate("/pages");
          }}
        >
          <PagesIcon />
          <span>Pages</span>
        </a>
      </li>
      {/* <li className={endpoint === "/ai-queries" ? "nav-item active " : "nav-item"}>
        <a
          className="nav-link"
          onClick={() => {
            dispatch(setDrawerClose(!DrawerValue));
            navigate("/ai-queries");
          }}
        >
          <AiQueries/>
          <span>AI Queries</span>
        </a>
      </li> */}
    </ul>
  );
}

export default Drawer;
