/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../css/sb-admin-2.min.css";
import { Calendar, DownArrow } from "../../components/svgicons";
import "./styles.scss";
import Drawer from "../../components/Drawer";
import Navbar from "../../components/Navbar";
import { message } from "antd";
import {
  getDefaultRevenueOption,
  getDefaultUserOption,
  getDrawerValue,
  getNoUserdataFound,
  getNodataFound,
  getPieChartData,
  getRevenueData,
  getSalesSummary,
  getUserChartData,
  setDefaultRevenueOption,
  setDefaultUserOption,
  setDrawerClose,
} from "./DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Piechart from "./Piechart";
import Linechart from "./Linechart";
import {
  getLineChartOfEarnings,
  getLineChartOfUserOverview,
  getPieChartOfAllAccounts,
  getSalesAndSummary,
} from "./DashboardApi";
import Loader from "../../loader/loader";
import Userchart from "./UserChart";

const Dashboard = () => {
  const hasFetchedData = useRef(false);
  const DrawerValue = useSelector(getDrawerValue);
  const dispatch = useDispatch();
  const revenue_data = useSelector(getRevenueData);
  const defaultUseroption = useSelector(getDefaultUserOption);
  const defaultRevenueOption = useSelector(getDefaultRevenueOption);
  const [optionForEarningsOverview, setOptionForEarningsOverview] = useState(
    defaultRevenueOption ? defaultRevenueOption : "monthly"
  );
  const [optionForUserOverview, setOptionForUserOverview] = useState(
    defaultUseroption ? defaultUseroption : "Month"
  );
  const pieChartData = useSelector(getPieChartData);
  const user_data = useSelector(getUserChartData);
  const noData = useSelector(getNodataFound);
  const noUserData = useSelector(getNoUserdataFound);
  const dates = revenue_data.map((item) => item.interval);
  const revenues = revenue_data.map((item) => item.revenue);
  const saleAndSummaryData = useSelector(getSalesSummary);
  const fetchRevenue = useCallback(
    async (option) => {
      const actionResult = await dispatch(getLineChartOfEarnings(option));
      if (getLineChartOfEarnings.rejected.match(actionResult)) {
        console.log(" actionResult.payload ", actionResult.payload);
        message.error(
          actionResult.payload ||
            "An error occurred while fetching revenue data"
        );
      }
    },
    [dispatch]
  );

  const fetchPieChartForAccounts = useCallback(async () => {
    const actionResult = await dispatch(getPieChartOfAllAccounts());
    if (getPieChartOfAllAccounts.rejected.match(actionResult)) {
      console.log(" actionResult.payload ", actionResult.payload);
      message.error(
        actionResult.payload || "An error occurred while fetching accounts data"
      );
    }
  }, [dispatch]);

  const fetchLineChartForUserOverview = useCallback(
    async (option) => {
      const actionResult = await dispatch(getLineChartOfUserOverview(option));
      if (getLineChartOfUserOverview.rejected.match(actionResult)) {
        console.log(" actionResult.payload ", actionResult.payload);
        message.error(
          actionResult.payload || "An error occurred while fetching user data"
        );
      }
    },
    [dispatch]
  );

  const fetchSalesAndSummary = useCallback(async () => {
    const actionResult = await dispatch(getSalesAndSummary());
    if (getSalesAndSummary.rejected.match(actionResult)) {
      console.log(" actionResult.payload ", actionResult.payload);
      message.error(
        actionResult.payload ||
          "An error occurred while fetching sale summery data"
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchRevenue(optionForEarningsOverview);
      fetchPieChartForAccounts();
      fetchLineChartForUserOverview(optionForUserOverview);
      fetchSalesAndSummary();

      hasFetchedData.current = true;
    }
  }, [
    fetchRevenue,
    fetchPieChartForAccounts,
    fetchLineChartForUserOverview,
    fetchSalesAndSummary,
    optionForEarningsOverview,
    optionForUserOverview,
  ]);

  const handleSelect = (option) => {
    setOptionForEarningsOverview(option);
    dispatch(setDefaultRevenueOption(option));
    if (option) {
      fetchRevenue(option);
    }
  };
  const handleSelectForUser = (option) => {
    setOptionForUserOverview(option);
    dispatch(setDefaultUserOption(option));
    if (option) {
      fetchLineChartForUserOverview(option);
    }
  };
  const isDataEmpty =
    pieChartData.active === 0 &&
    pieChartData.inactive === 0 &&
    pieChartData.pending === 0;

  return (
    <>
      <div id="wrapper" className={DrawerValue ? "" : "sb-sidenav-toggled"}>
        <Drawer />

        <div id="content-wrapper" className="d-flex flex-column customDashbard">
          <div
            id="content"
            onClick={() => {
              if (!DrawerValue) {
                // Only dispatch if DrawerValue is false
                dispatch(setDrawerClose(true)); // Set DrawerValue to true
              }
            }}
          >
            <Navbar />
            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <div className="dashboard-heading">
                  <h1 className="page-title">Dashboard</h1>
                  <p className="page-para">Sales overview & summary</p>
                </div>
                {/* <div className="custom-dropdowns">
                  <div className="dropdown">
                    <button
                      className="btn btn-outline dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Last Month <DownArrow />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              {/* <!-- Content Row --> */}
              <div className="row">
                {/* <!-- Earnings (Today) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-success h-100 ">
                    <div className="card-body">
                      <div className="row  align-items-center">
                        <div className="col mr-2">
                          <div className="earning-heading">
                            Earnings (Today)
                          </div>
                          <div className="earning-point">
                            {saleAndSummaryData?.daily?.current_revenue
                              ? `$${Math.abs(
                                  saleAndSummaryData?.daily?.current_revenue ?? 0
                                ).toLocaleString()}`
                              : "00"}
                          </div>
                          <div className="earning-subheading">
                            <span>
                              {saleAndSummaryData?.daily?.percent_difference
                                ? saleAndSummaryData?.daily?.percent_difference
                                : "0.0"}
                              %
                            </span>{" "}
                            from last Day
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-success h-100 ">
                    <div className="card-body">
                      <div className="row  align-items-center">
                        <div className="col mr-2">
                          <div className="earning-heading">
                            Earnings (This Month)
                          </div>
                          <div className="earning-point">
                            {saleAndSummaryData?.monthly?.current_revenue
                              ? `$${Math.abs(
                                  saleAndSummaryData?.monthly?.current_revenue ?? 0
                                ).toLocaleString()}`
                              : "00"}
                          </div>
                          <div className="earning-subheading">
                            <span>
                              {" "}
                              {saleAndSummaryData?.monthly?.percent_difference
                                ? saleAndSummaryData?.monthly
                                    ?.percent_difference
                                : "0.0"}
                              %
                            </span>{" "}
                            from last Month
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Quarterly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-success h-100 ">
                    <div className="card-body">
                      <div className="row  align-items-center">
                        <div className="col mr-2">
                          <div className="earning-heading">
                            Earnings (Quarterly)
                          </div>
                          <div className="earning-point">
                            {" "}
                            {saleAndSummaryData?.quarterly?.current_revenue
                              ? `$${Math.abs(
                                  saleAndSummaryData?.quarterly?.current_revenue ?? 0
                                ).toLocaleString()}`
                              : "00"}
                          </div>
                          <div className="earning-subheading">
                            <span>
                              {" "}
                              {saleAndSummaryData?.quarterly?.percent_difference
                                ? saleAndSummaryData?.quarterly
                                    ?.percent_difference
                                : "0.0"}
                              %
                            </span>{" "}
                            from last Quarterly
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Yearly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-success h-100 ">
                    <div className="card-body">
                      <div className="row  align-items-center">
                        <div className="col mr-2">
                          <div className="earning-heading">
                            Earnings (Yearly)
                          </div>
                          <div className="earning-point">
                            {saleAndSummaryData?.yearly?.current_revenue
                              ? `$${Math.abs(
                                  saleAndSummaryData?.yearly?.current_revenue ?? 0
                                ).toLocaleString()}`
                              : "00"}
                          </div>
                          <div className="earning-subheading">
                            <span>
                              {" "}
                              {saleAndSummaryData?.yearly?.percent_difference
                                ? saleAndSummaryData?.yearly?.percent_difference
                                : "0.0"}
                              %
                            </span>{" "}
                            from last Year
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}
              <div className="graph-content">
                <div className="row">
                  {/* <!-- Area Chart --> */}
                  <div className="col-xl-8 col-lg-7 mb-4">
                    <div className="card shadow">
                      {/* <!-- Card Header - Dropdown --> */}
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 page-para ">Earnings Overview</h6>
                        <div className="dropdown weekly-dropdown ">
                          <button
                            className="btn btn-outline dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <Calendar />{" "}
                            {optionForEarningsOverview.charAt(0).toUpperCase() +
                              optionForEarningsOverview.slice(1)}
                            <DownArrow />
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className={
                                  optionForEarningsOverview === "daily"
                                    ? "dropdown-item active"
                                    : "dropdown-item"
                                }
                                onClick={() => handleSelect("daily")}
                              >
                                Daily
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  optionForEarningsOverview === "weekly"
                                    ? "dropdown-item active"
                                    : "dropdown-item"
                                }
                                onClick={() => handleSelect("weekly")}
                              >
                                Weekly
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  optionForEarningsOverview === "monthly"
                                    ? "dropdown-item active"
                                    : "dropdown-item"
                                }
                                onClick={() => handleSelect("monthly")}
                              >
                                Monthly
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  optionForEarningsOverview === "yearly"
                                    ? "dropdown-item active"
                                    : "dropdown-item"
                                }
                                onClick={() => handleSelect("yearly")}
                              >
                                Yearly
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <!-- Card Body --> */}
                      <div className="card-body">
                        <div className="chart-area">
                          {!noData &&
                          dates.length === 0 &&
                          revenues.length === 0 ? (
                            <Loader />
                          ) : noData &&
                            dates.length === 0 &&
                            revenues.length === 0 ? (
                            <div>No data found</div>
                          ) : (
                            <Linechart date={dates} series={revenues} />
                          )}

                          {/* <canvas id="myAreaChart"></canvas> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Pie Chart --> */}
                  <div className="col-xl-4 col-lg-5 mb-4">
                    <div className="card shadow">
                      {/* <!-- Card Header - Dropdown --> */}
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 page-para">FA Accounts Overview</h6>
                      </div>
                      {/* <!-- Card Body --> */}
                      <div className="card-body">
                        <div className="chart-pie pt-4 pb-2">
                          {isDataEmpty ? (
                            <Loader />
                          ) : (
                            <Piechart piechartdata={pieChartData} />
                          )}

                          {/* <canvas id="myPieChart"></canvas> */}
                        </div>
                        <div className="mt-4 text-center small">
                          <span className="mr-2">
                            <i
                              className="fa fa-circle-o text-success"
                              style={{ fontSize: "17px", fontWeight: "800" }}
                            ></i>{" "}
                            Activate
                          </span>
                          <span className="mr-2">
                            <i
                              className="fa fa-circle-o text-danger"
                              style={{ fontSize: "17px", fontWeight: "800" }}
                            ></i>{" "}
                            Inactive
                          </span>
                          <span className="mr-2">
                            <i
                              className="fa fa-circle-o text-warning"
                              style={{ fontSize: "17px", fontWeight: "800" }}
                            ></i>{" "}
                            Pending
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 mb-4">
                    <div className="card shadow">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 page-para ">Users</h6>
                        <div className="dropdown weekly-dropdown ">
                          <button
                            className="btn btn-outline dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <Calendar />
                            {optionForUserOverview === "Today"
                              ? "Dai"
                              : optionForUserOverview}
                            ly <DownArrow />
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className={
                                  optionForUserOverview === "Today"
                                    ? "dropdown-item active"
                                    : "dropdown-item"
                                }
                                onClick={() => handleSelectForUser("Today")}
                              >
                                Daily
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  optionForUserOverview === "Week"
                                    ? "dropdown-item active"
                                    : "dropdown-item"
                                }
                                onClick={() => handleSelectForUser("Week")}
                              >
                                Weekly
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  optionForUserOverview === "Month"
                                    ? "dropdown-item active"
                                    : "dropdown-item"
                                }
                                onClick={() => handleSelectForUser("Month")}
                              >
                                Monthly
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  optionForUserOverview === "Year"
                                    ? "dropdown-item active"
                                    : "dropdown-item"
                                }
                                onClick={() => handleSelectForUser("Year")}
                              >
                                Yearly
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="chart-area">
                          {!noUserData && user_data.length === 0 ? (
                            <Loader />
                          ) : noUserData && user_data.length === 0 ? (
                            <div>No data found</div>
                          ) : (
                            <Userchart user={user_data} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </>
  );
};

export default Dashboard;
